"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.randomInt = randomInt;
exports.randomFloat = randomFloat;
exports.onlyNumber = onlyNumber;
exports.onlyNumberFromCurrency = onlyNumberFromCurrency;
exports.formatFileSize = formatFileSize;
exports.inRange = inRange;
exports.isMaiorQue = isMaiorQue;
exports.isMenorQue = isMenorQue;

function randomInt(min, max) {
  return min + Math.floor((max - min) * Math.random());
}

function randomFloat(min, max) {
  return min + (max - min) * Math.random();
}

function onlyNumber(str) {
  return str ? str.toString().replace(/\D/g, '') : str;
}

function onlyNumberFromCurrency(number) {
  return (number || '').toString().replace(/\./g, '').replace(',', '.').replace(/[^\d.]/g, '');
}

function formatFileSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (parseInt(bytes, 10) === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return "".concat(Math.round(bytes / Math.pow(1024, i), 2), " ").concat(sizes[i]);
}

function inRange(number, min, max) {
  return (number - min) * (number - max) <= 0;
}

function isMaiorQue(valorA, valorB) {
  return parseFloat(valorA) > parseFloat(valorB);
}

function isMenorQue(valorA, valorB) {
  return parseFloat(valorA) < parseFloat(valorB);
}