"use strict";

var _moment = _interopRequireDefault(require("moment"));

var Yup = _interopRequireWildcard(require("yup"));

var _object = require("@sponte/lib-utils/dist/helpers/object");

var validations = _interopRequireWildcard(require("../helpers/validations"));

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

Yup.addMethod(Yup.string, 'cpf', function cpfValidator() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'CPF inválido';
  return this.test('cpf', message, validations.isValidCPF);
});
Yup.addMethod(Yup.string, 'cnpj', function cnpjValidator() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'CNPJ inválido';
  return this.test('cnpj', message, validations.isValidCNPJ);
});
Yup.addMethod(Yup.string, 'passwordStrength', function passwordStrengthValidator() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Senha fraca';
  return this.test('passwordStrength', message, validations.isPasswordStrength);
});
Yup.addMethod(Yup.date, 'minDateToday', function minDateTodayValidator() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Não pode ser menor que hoje';
  return this.test('minDateToday', message, validations.dateIsGreaterThanToday);
});
Yup.addMethod(Yup.date, 'maxDateToday', function maxDateTodayValidator() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Não pode ser maior que hoje';
  return this.test('maxDateToday', message, validations.dateIsLessThanToday);
});
Yup.addMethod(Yup.string, 'hoursOrMinutesIsGreaterThanPattern', function hoursOrMinutesIsGreaterThanPatternValidator() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Horas maior que 23 ou minutos maior que 59';
  return this.test('hoursOrMinutesIsGreaterThanPattern', message, validations.hoursOrMinutesIsGreaterThanPattern);
});
Yup.addMethod(Yup.date, 'isValidDate', function isValidDateValidator() {
  return this.transform(function (_, originalValue) {
    return !originalValue || !originalValue.length ? null : (0, _moment.default)(originalValue, ['YYYY-MM-DDTHH:mm:ss.SSSS', 'YYYY-MM-DDTHH:mm:ssZ', 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD'], true).startOf('day').toDate();
  });
});
Yup.addMethod(Yup.object, 'isTextArea', function isValidDateValidator() {
  return this.transform(function (_, originalValue) {
    if ((0, _object.getSafe)(originalValue, 'blocks[0].text')) {
      return originalValue;
    }

    return null;
  });
});
Yup.addMethod(Yup.string, 'isValidCreditCard', function validCreditCardValidator() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Cartão de crédito inválido';
  return this.test('isValidCreditCard', message, validations.isValidCreditCard);
});
Yup.addMethod(Yup.string, 'emailCustom', function isValideEmail() {
  return this.email('Email inválido').matches(/^[a-zA-Z0-9|@|.|_|-]+$/, 'Apenas números, letras e os caracteres (.), (_) e (-) são permitidos');
});
Yup.addMethod(Yup.string, 'isValidExpirationDate', function expirationDateValidator() {
  return this.matches(/^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/, 'Data de validade incorreta');
});
Yup.addMethod(Yup.string, 'isValidBoleto', function boletoValidator() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Boleto inválido';
  return this.test('isValidBoleto', message, validations.isValidBoleto);
});