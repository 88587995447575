import _slicedToArray from "/vercel/path1/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _objectWithoutProperties from "/vercel/path1/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _defineProperty from "/vercel/path1/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useCallback } from 'react';
import { useReducer } from './useReducer';
var stateReducerTypes = {
  open: 'OPEN',
  close: 'CLOSE',
  toggle: 'TOGGLE'
};

function disclosureReducer(state, _ref) {
  var type = _ref.type,
      _ref$payload = _ref.payload,
      payload = _ref$payload === void 0 ? {} : _ref$payload;

  switch (type) {
    case stateReducerTypes.toggle:
      {
        return _objectSpread({}, payload, {
          isOpen: !state.isOpen
        });
      }

    case stateReducerTypes.open:
      {
        return _objectSpread({}, payload, {
          isOpen: true
        });
      }

    case stateReducerTypes.close:
      {
        return _objectSpread({}, payload, {
          isOpen: false
        });
      }

    default:
      {
        return state;
      }
  }
}

var defaultIsOpen = false;

var defaultReducer = function defaultReducer(_, __, c) {
  return c;
};

export function useDisclosure() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref2$isOpen = _ref2.isOpen,
      isOpen = _ref2$isOpen === void 0 ? defaultIsOpen : _ref2$isOpen,
      _ref2$reducer = _ref2.reducer,
      reducer = _ref2$reducer === void 0 ? defaultReducer : _ref2$reducer,
      defaultParams = _objectWithoutProperties(_ref2, ["isOpen", "reducer"]);

  var _useReducer = useReducer(function (s, action) {
    var changes = disclosureReducer(s, action);
    return reducer(s, action, changes);
  }, _objectSpread({
    isOpen: isOpen
  }, defaultParams)),
      _useReducer2 = _slicedToArray(_useReducer, 2),
      state = _useReducer2[0],
      dispatch = _useReducer2[1];

  var handleOpen = useCallback(function () {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    dispatch(stateReducerTypes.open, _objectSpread({}, defaultParams, {}, params));
  }, [dispatch, defaultParams]);
  var handleClose = useCallback(function () {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    dispatch(stateReducerTypes.close, _objectSpread({}, defaultParams, {}, params));
  }, [dispatch, defaultParams]);
  var handleToggle = useCallback(function () {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    dispatch(stateReducerTypes.toggle, _objectSpread({}, defaultParams, {}, params));
  }, [dispatch, defaultParams]);
  return _objectSpread({}, state, {
    onOpen: handleOpen,
    onClose: handleClose,
    onToggle: handleToggle
  });
}
useDisclosure.types = stateReducerTypes;