"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pickRandomObjectValue = pickRandomObjectValue;
exports.getSafe = getSafe;

var _number = require("./number");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function pickRandomObjectValue(obj) {
  var keys = Object.keys(obj);

  if (keys.length === 0) {
    return null;
  }

  var key = keys[(0, _number.randomInt)(0, keys.length)];
  return obj[key];
}

function getSafe(object, path) {
  var fallback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

  var obj = _objectSpread({}, object);

  var arrToPath = path.replace(/\[(\w+)\]/g, '.$1').replace(/^\./, '').split('.');

  try {
    var i = 0;

    while (i < arrToPath.length) {
      if (arrToPath[i] in obj) {
        obj = obj[arrToPath[i]];
        i += 1;
      } else {
        return fallback;
      }
    }

    return obj;
  } catch (err) {
    return fallback;
  }
}