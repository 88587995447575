"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createContainer = createContainer;

var _react = _interopRequireWildcard(require("react"));

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function createUseContext(context) {
  return function () {
    return (0, _react.useContext)(context);
  };
}

function createContainer(useValue) {
  for (var _len = arguments.length, splitValues = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    splitValues[_key - 1] = arguments[_key];
  }

  var Context = (0, _react.createContext)();

  var Provider = function Provider(_ref) {
    var children = _ref.children,
        props = _objectWithoutProperties(_ref, ["children"]);

    var value = useValue(props);
    var createMemoDeps = splitValues[0];
    var deps = createMemoDeps && createMemoDeps(value);
    var memoizedValue = Array.isArray(deps) ? (0, _react.useMemo)(function () {
      return value;
    }, deps) : value;
    return _react.default.createElement(Context.Provider, {
      value: memoizedValue
    }, children);
  };

  var useContainer = createUseContext(Context);
  useContainer.Context = Context;
  useContainer.Provider = Provider;
  var tuple = [];

  if (!splitValues.length) {
    tuple.push(Provider, createUseContext(Context));
  } else {
    var contexts = [];

    var SplitProvider = function SplitProvider(props) {
      var value = useValue(props);
      var children = props.children;

      for (var i = 0; i < contexts.length; i += 1) {
        var context = contexts[i];
        var splitValue = splitValues[i];
        children = _react.default.createElement(context.Provider, {
          value: splitValue(value)
        }, children);
      }

      return children;
    };

    tuple.push(SplitProvider);

    for (var i = 0; i < splitValues.length; i += 1) {
      var context = (0, _react.createContext)();
      contexts.push(context);
      tuple.push(createUseContext(context));
    }
  }

  for (var _i = 0; _i < tuple.length; _i += 1) {
    useContainer[_i] = tuple[_i];
  }

  if (typeof Symbol === 'function' && Symbol.iterator) {
    useContainer[Symbol.iterator] = function () {
      return tuple[Symbol.iterator]();
    };
  }

  return useContainer;
}