"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SptIcon = exports.SptIconStyled = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _react2 = require("@mdi/react");

var MDIcons = _interopRequireWildcard(require("@mdi/js"));

var _useTheme = require("@sponte/lib-utils/dist/hooks/useTheme");

var _tools = require("@sponte/lib-utils/dist/theme/tools");

var _Box = require("../../elements/Box");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n  flex-shrink: 0;\n  stroke-width: 0;\n  vertical-align: middle;\n  backface-visibility: hidden;\n\n  &:not(:root) {\n    overflow: hidden;\n  }\n\n  cursor: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var SptIconStyled = (0, _styledComponents.default)(_Box.SptBox)(_templateObject(), (0, _tools.fontSize)(), (0, _tools.ifProp)('onClick', 'pointer', 'unset'));
exports.SptIconStyled = SptIconStyled;
SptIconStyled.displayName = 'SptIconStyled';
var SptIcon = (0, _react.memo)((0, _react.forwardRef)(function (props, ref) {
  var theme = (0, _useTheme.useTheme)();
  var iconName = props.children;
  var iconNameSplited = iconName.split('-');
  var iconNameMDI = "mdi".concat(iconNameSplited.map(function (i) {
    return i.charAt(0).toUpperCase() + i.slice(1);
  }).join(''));
  var icon = theme.icons[iconName] || null;
  var iconMDI = MDIcons[iconNameMDI];
  return _react.default.createElement(SptIconStyled, _extends({
    ref: ref,
    as: "svg",
    display: "inline-block",
    viewBox: icon && icon.viewBox || '0 0 24 24'
  }, props), icon ? icon.path : _react.default.createElement(_react2.Icon, {
    path: iconMDI,
    className: "mdi ".concat(iconName).trim()
  }));
}));
exports.SptIcon = SptIcon;
SptIcon.displayName = 'SptIcon';
SptIcon.propTypes = {
  color: _propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.string, _propTypes.default.array, _propTypes.default.object]),
  fontSize: _propTypes.default.oneOfType([_propTypes.default.number, _propTypes.default.string, _propTypes.default.array, _propTypes.default.object])
};
SptIcon.defaultProps = {
  color: 'darkGrey',
  fontSize: 'xlarge',
  role: 'presentation',
  focusable: false,
  width: '1em',
  height: '1em'
};