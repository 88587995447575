"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _en = _interopRequireDefault(require("./en"));

var _ptBR = _interopRequireDefault(require("./pt-BR"));

var _sp = _interopRequireDefault(require("./sp"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var _default = {
  'pt-BR': _ptBR.default,
  en: _en.default,
  sp: _sp.default
};
exports.default = _default;