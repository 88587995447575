"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createColorVariants = exports.getColorVariantDark = exports.getColorVariantLight = void 0;

var _polished = require("polished");

var getColorVariantLight = function getColorVariantLight(main) {
  var percentage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.6;
  return (0, _polished.tint)(percentage, main);
};

exports.getColorVariantLight = getColorVariantLight;

var getColorVariantDark = function getColorVariantDark(main) {
  var percentage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.4;
  return (0, _polished.shade)(percentage, main);
};

exports.getColorVariantDark = getColorVariantDark;

var createColorVariants = function createColorVariants(main) {
  return {
    main: main,
    light: getColorVariantLight(main),
    dark: getColorVariantDark(main)
  };
};

exports.createColorVariants = createColorVariants;