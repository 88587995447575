"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SptImagemFeedback = exports.SptImagemFeedbackImg = exports.SptImagemFeedbackStyled = exports.SptAssetsImages = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _useTheme2 = require("@sponte/lib-utils/dist/hooks/useTheme");

var _Flex = require("../../elements/Flex");

var _Text = require("../../elements/Text");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  max-width: 200px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  img {\n    margin-left: auto;\n    margin-right: auto;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var SptAssetsImages = {
  notFound: 'notFound',
  illustration: 'illustration',
  logo: 'logo',
  login: 'login',
  confirmacaoAgendamento: 'confirmacaoAgendamento'
};
exports.SptAssetsImages = SptAssetsImages;
var SptImagemFeedbackStyled = (0, _styledComponents.default)(_Flex.SptFlex)(_templateObject());
exports.SptImagemFeedbackStyled = SptImagemFeedbackStyled;

var SptImagemFeedbackImg = _styledComponents.default.img(_templateObject2());

exports.SptImagemFeedbackImg = SptImagemFeedbackImg;
SptImagemFeedbackStyled.displayName = 'SptImagemFeedbackStyled';

var SptImagemFeedback = function SptImagemFeedback(_ref) {
  var _ref$imageAssetsPath = _ref.imageAssetsPath,
      imageAssetsPath = _ref$imageAssetsPath === void 0 ? 'notFound' : _ref$imageAssetsPath,
      imageWidth = _ref.imageWidth,
      information = _ref.information,
      instruction = _ref.instruction,
      informationColor = _ref.informationColor,
      props = _objectWithoutProperties(_ref, ["imageAssetsPath", "imageWidth", "information", "instruction", "informationColor"]);

  var _useTheme = (0, _useTheme2.useTheme)(),
      assets = _useTheme.assets;

  return _react.default.createElement(SptImagemFeedbackStyled, _extends({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }, props), _react.default.createElement(_Flex.SptFlex, {
    width: 1,
    mb: 10
  }, _react.default.createElement(SptImagemFeedbackImg, {
    src: assets[imageAssetsPath],
    alt: information
  })), _react.default.createElement(_Flex.SptFlex, {
    width: 1
  }, _react.default.createElement(_Text.SptText, {
    fontSize: 3,
    fontWeight: 2,
    mx: "auto",
    mb: 10,
    textAlign: "center",
    color: informationColor
  }, information)), _react.default.createElement(_Flex.SptFlex, {
    width: 1
  }, _react.default.createElement(_Text.SptText, {
    variant: "label",
    fontSize: 2,
    mx: "auto",
    textAlign: "center"
  }, instruction)));
};

exports.SptImagemFeedback = SptImagemFeedback;
SptImagemFeedback.displayName = 'SptImagemFeedback';
SptImagemFeedback.propTypes = {
  imageAssetsPath: _propTypes.default.oneOf(Object.keys(SptAssetsImages)),
  information: _propTypes.default.string,
  informationColor: _propTypes.default.string,
  instruction: _propTypes.default.string
};
SptImagemFeedback.defaultProps = {
  imageAssetsPath: 'notFound',
  informationColor: 'almostBlack'
};