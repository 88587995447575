import { useMemo } from 'react';
import { checkDeps, useDeepCompareMemoize } from './useDeepCompareMemoize';
/**
 * https://github.com/kentcdodds/use-deep-compare-effect
 */

export function useDeepCompareMemo(factory, inputs) {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(inputs, 'useDeepCompareMemo');
  }

  return useMemo(factory, useDeepCompareMemoize(inputs));
}