"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createI18n = void 0;

var _reactI18next = require("react-i18next");

var _moment = _interopRequireDefault(require("moment"));

var _i18next = _interopRequireDefault(require("i18next"));

require("moment/locale/pt-br");

var _base = _interopRequireDefault(require("./base"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var deepmerge = require('deepmerge');

_moment.default.locale('pt-br', {
  months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
  weekdaysMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
});

var createI18n = function createI18n() {
  var resources = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var instance = _i18next.default.createInstance();

  instance.use(_reactI18next.initReactI18next);
  instance.init({
    resources: deepmerge(_base.default, resources),
    debug: false,
    lng: 'pt-BR',
    fallbackLng: ['pt-BR', 'en', 'sp'],
    ns: 'comum',
    defaultNS: 'comum',
    fallbackNS: 'comum',
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default'
    },
    interpolation: {
      escapeValue: false,
      format: function format(value, _format, lang) {
        if (!value || value === '' || value === undefined || value === null) {
          return '';
        }

        var _format$split = _format.split('|'),
            _format$split2 = _slicedToArray(_format$split, 2),
            type = _format$split2[0],
            mask = _format$split2[1];

        if (type === 'date') {
          return (0, _moment.default)(value).format(mask);
        }

        if (type === 'currency') {
          return new Intl.NumberFormat(lang, {
            style: 'currency',
            currency: mask,
            currencyDisplay: 'code'
          }).format(value).replace(mask, '').replace(' ', '').replace(' ', '').trim();
        }

        if (type === 'percent') {
          return new Intl.NumberFormat(lang, {
            style: 'unit',
            unit: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).format(value);
        }

        return value;
      }
    }
  });
  return instance;
};

exports.createI18n = createI18n;