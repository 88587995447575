"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SptGlobalCss = void 0;

var _styledComponents = require("styled-components");

var _tools = require("@sponte/lib-utils/dist/theme/tools");

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  html {\n    scroll-behavior: smooth;\n  }\n\n  html,\n  body,\n  div,\n  span,\n  applet,\n  object,\n  iframe,\n  h1,\n  h2,\n  h3,\n  h4,\n  h5,\n  h6,\n  p,\n  blockquote,\n  pre,\n  a,\n  abbr,\n  acronym,\n  address,\n  big,\n  cite,\n  code,\n  del,\n  dfn,\n  em,\n  img,\n  ins,\n  kbd,\n  q,\n  s,\n  samp,\n  small,\n  strike,\n  strong,\n  sub,\n  sup,\n  tt,\n  var,\n  b,\n  u,\n  i,\n  center,\n  dl,\n  dt,\n  dd,\n  ol,\n  ul,\n  li,\n  fieldset,\n  form,\n  label,\n  legend,\n  table,\n  caption,\n  tbody,\n  tfoot,\n  thead,\n  tr,\n  th,\n  td,\n  article,\n  aside,\n  canvas,\n  details,\n  embed,\n  figure,\n  figcaption,\n  footer,\n  header,\n  hgroup,\n  menu,\n  nav,\n  output,\n  ruby,\n  section,\n  summary,\n  time,\n  mark,\n  audio,\n  video {\n    margin: 0;\n    padding: 0;\n    border: 0;\n    font-size: 100%;\n    vertical-align: baseline;\n  }\n\n  article,\n  aside,\n  details,\n  figcaption,\n  figure,\n  footer,\n  header,\n  hgroup,\n  menu,\n  nav,\n  section {\n    display: block;\n  }\n  ol,\n  ul {\n    list-style: none;\n  }\n  blockquote,\n  q {\n    quotes: none;\n  }\n  blockquote:before,\n  blockquote:after,\n  q:before,\n  q:after {\n    content: '';\n    content: none;\n  }\n  table {\n    border-collapse: collapse;\n    border-spacing: 0;\n  }\n\n  /* CUSTOM */\n  *,\n  *::before,\n  *::after {\n    box-sizing: border-box;\n  }\n\n  * {\n    outline: none;\n  }\n\n  *:disabled, *[disabled],*:disabled *, *[disabled] * {\n    cursor: default !important;\n  }\n\n  body {\n    line-height: 1;\n    font-family: ", ";\n    font-weight: ", ";\n    color: ", ";\n    background-color: ", ";\n\n    @media print {\n      background-color: ", ";\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var SptGlobalCss = (0, _styledComponents.createGlobalStyle)(_templateObject(), (0, _tools.theme)('fonts.default'), (0, _tools.theme)('fontWeights.medium'), (0, _tools.theme)('colors.almostBlack'), (0, _tools.theme)('colors.lightGrey'), (0, _tools.theme)('colors.white'));
exports.SptGlobalCss = SptGlobalCss;
SptGlobalCss.displayName = 'SptGlobalCss';
SptGlobalCss.propTypes = {};
SptGlobalCss.defaultProps = {};