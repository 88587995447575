"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isValidBoleto = exports.isValidCreditCard = exports.isPasswordStrength = exports.isPessoaJuridica = exports.isPessoaFisica = exports.isValidCNPJ = exports.isValidCPF = exports.isRepeatedString = exports.hoursOrMinutesIsGreaterThanPattern = exports.dateIsGreaterThanToday = exports.dateIsLessThanToday = void 0;

var _moment = _interopRequireDefault(require("moment"));

var _payment = _interopRequireDefault(require("payment"));

var _boleto = require("./boleto");

var _number = require("./number");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var dateIsLessThanToday = function dateIsLessThanToday() {
  var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  if (!date) {
    return true;
  }

  return (0, _moment.default)(date).startOf('day') < (0, _moment.default)().endOf('day').toDate();
};

exports.dateIsLessThanToday = dateIsLessThanToday;

var dateIsGreaterThanToday = function dateIsGreaterThanToday() {
  var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  if (!date) {
    return true;
  }

  return (0, _moment.default)(date).endOf('day') > (0, _moment.default)().startOf('day').toDate();
};

exports.dateIsGreaterThanToday = dateIsGreaterThanToday;

var hoursOrMinutesIsGreaterThanPattern = function hoursOrMinutesIsGreaterThanPattern() {
  var time = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  if (!time) {
    return true;
  }

  if (time.length < 5) {
    return false;
  }

  var str = time.split('T').pop().split(':');

  var _ref = str.length > 1 ? str.slice(0, 2) : str,
      _ref2 = _slicedToArray(_ref, 2),
      hours = _ref2[0],
      minutes = _ref2[1];

  if (parseInt(hours, 10) > 23) {
    return false;
  }

  if (parseInt(minutes, 10) > 59) {
    return false;
  }

  return true;
};

exports.hoursOrMinutesIsGreaterThanPattern = hoursOrMinutesIsGreaterThanPattern;

var isRepeatedString = function isRepeatedString(str) {
  return str.split('').every(function (_char) {
    return _char === str[0];
  });
};

exports.isRepeatedString = isRepeatedString;

var isValidCPF = function isValidCPF(str) {
  var cpf = (0, _number.onlyNumber)(str);

  if (!cpf) {
    return true;
  }

  if (cpf.length !== 11 || isRepeatedString(cpf)) {
    return false;
  }

  var sum = 0;
  var rest;
  var i;

  for (i = 1; i <= 9; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  }

  rest = sum * 10 % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(cpf.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;

  for (i = 1; i <= 10; i += 1) {
    sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  }

  rest = sum * 10 % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(cpf.substring(10, 11), 10)) {
    return false;
  }

  return true;
};

exports.isValidCPF = isValidCPF;

var isValidCNPJ = function isValidCNPJ(str) {
  var cnpj = (0, _number.onlyNumber)(str);

  if (!cnpj) {
    return true;
  }

  if (cnpj.length !== 14 || isRepeatedString(cnpj)) {
    return false;
  }

  var length = cnpj.length - 2;
  var numbers = cnpj.substring(0, length);
  var digits = cnpj.substring(length);
  var sum = 0;
  var pos = length - 7;
  var i;
  var result;

  for (i = length; i >= 1; i -= 1) {
    sum += numbers.charAt(length - i) * pos;
    pos -= 1;

    if (pos < 2) {
      pos = 9;
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - sum % 11;

  if (result.toString() !== digits.charAt(0)) {
    return false;
  }

  length += 1;
  numbers = cnpj.substring(0, length);
  sum = 0;
  pos = length - 7;

  for (i = length; i >= 1; i -= 1) {
    sum += numbers.charAt(length - i) * pos;
    pos -= 1;

    if (pos < 2) {
      pos = 9;
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - sum % 11;

  if (result.toString() !== digits.charAt(1)) {
    return false;
  }

  return true;
};

exports.isValidCNPJ = isValidCNPJ;

var isPessoaFisica = function isPessoaFisica(type) {
  return type === 'FISICA';
};

exports.isPessoaFisica = isPessoaFisica;

var isPessoaJuridica = function isPessoaJuridica(type) {
  return type === 'JURIDICA';
};

exports.isPessoaJuridica = isPessoaJuridica;

var isPasswordStrength = function isPasswordStrength() {
  var password = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  if (!password) {
    return true;
  }

  if (password.length < 8 || !/[a-z]/.test(password) || !/[A-Z]/.test(password) || !/\d+/.test(password) || !/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    return false;
  }

  return true;
};

exports.isPasswordStrength = isPasswordStrength;

var isValidCreditCard = function isValidCreditCard(number) {
  return _payment.default.fns.validateCardNumber(number);
};

exports.isValidCreditCard = isValidCreditCard;

var isValidBoleto = function isValidBoleto(numeroBoleto) {
  return (0, _boleto.boletoValidator)(numeroBoleto);
};

exports.isValidBoleto = isValidBoleto;