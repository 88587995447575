/* eslint-disable */
/* tslint:disable */
import ky from 'ky'
import { useQuery, useMutation } from 'react-query'

let api = ky.create({
  "prefixUrl": "https://rtc-saude-api.staging.sponte-demo.com.br",
  "throwHttpErrors": false,
  "retry": {
    "statusCodes": [
      401
    ]
  }
})

export const getApi = () => api

export const setApi = (newApi) => {
  api = newApi
}

export const extendApi = (options) => {
  api = getApi().extend(options)
}

const requestFn = async ({ url, method, pathParams, queryParams, ...rest }) => {
  const urlPathParams = url.match(/{([^}]+)}/g)

  if (urlPathParams) {
    url = urlPathParams.reduce((acc, param) => acc.replace(param, pathParams[param.replace(/{|}/g, '')]), url)
  } else {
    queryParams = pathParams
  }

  if (url.charAt(0) === '/') {
    url = url.replace('/', '')
  }

  const response = await api(url, {
    method,
    ...rest,
    searchParams: {
      ...(rest.searchParams || {}),
      ...queryParams
    }
  })

  let data

  try {
    const contentType = (response.headers.get('content-type') || '').split('; ')[0]

    const responseType =
      {
        'application/json': 'json',
        'application/pdf': 'blob',
        'application/octet-stream': 'blob'
      }[contentType] || 'text'

    data = await response[responseType]()
  } catch (e) {
    data = e.message
  }

  if (!response.ok) {
    const error = {
      data,
      status: response.status,
      message: `Failed to fetch: ${response.status} ${response.statusText}`
    }

    throw error
  }

  return data
}

const queryFn = (options = {}) => (url, pathParams = {}, queryParams = {}) =>
  requestFn({
    url,
    method: 'get',
    pathParams,
    queryParams,
    ...options
  })

const mutationFn = (
  method,
  url,
  pathParams = {},
  queryParams = {},
  options = {}
) => (body = {}) => {
  if (Array.isArray(body)) {
    pathParams = { ...pathParams, ...(body[0] || {}) }
    queryParams = { ...queryParams, ...(body[1] || {}) }
    options = { ...options, ...(body[3] || {}) }
    body = body[2]
  }

  const request = {
    url,
    method,
    pathParams,
    queryParams,
    ...options
  }

  if (method !== 'delete') {
    try {
      request[body.toString() === '[object FormData]' ? 'body' : 'json'] = body
    } catch(e) {
    }
  }

  return requestFn(request)
}

export const mutationChannelControllerCadastrarArquivo = (pathParams, options) => mutationFn('post', '/api/v1/channel/canais/{id}/arquivos', pathParams, {}, options)
export const useMutationChannelControllerCadastrarArquivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/channel/canais/{id}/arquivos', pathParams, {}, options), config)

export const mutationChannelControllerSilenciarCanal = (pathParams, options) => mutationFn('patch', '/api/v1/channel/canais/{id}/silenciamento', pathParams, {}, options)
export const useMutationChannelControllerSilenciarCanal = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/channel/canais/{id}/silenciamento', pathParams, {}, options), config)

export const mutationChannelControllerCadastrarCanalMensagem = (pathParams, options) => mutationFn('post', '/api/v1/channel/canais/{id}/mensagens', pathParams, {}, options)
export const useMutationChannelControllerCadastrarCanalMensagem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/channel/canais/{id}/mensagens', pathParams, {}, options), config)

export const queryChannelControllerObterCanalMensagensPorCanal = (pathParams, queryParams, options) => queryFn(options)('/api/v1/channel/canais/{id}/mensagens', pathParams, queryParams)
export const useQueryChannelControllerObterCanalMensagensPorCanal = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/channel/canais/{id}/mensagens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryChannelControllerObterCanalMensagensPorCanal.queryKey = '/api/v1/channel/canais/{id}/mensagens'

export const mutationChannelControllerApagarCanalMensagem = (pathParams, options) => mutationFn('patch', '/api/v1/channel/canais/{id}/mensagens/{canalMensagemId}', pathParams, {}, options)
export const useMutationChannelControllerApagarCanalMensagem = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/channel/canais/{id}/mensagens/{canalMensagemId}', pathParams, {}, options), config)

export const mutationChannelControllerLerCanalMensagens = (pathParams, options) => mutationFn('post', '/api/v1/channel/canais/{id}/leituras', pathParams, {}, options)
export const useMutationChannelControllerLerCanalMensagens = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/channel/canais/{id}/leituras', pathParams, {}, options), config)

export const queryChannelControllerObterCanaisIndividuais = (queryParams, options) => queryFn(options)('/api/v1/channel/canais-individuais', queryParams)
export const useQueryChannelControllerObterCanaisIndividuais = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/channel/canais-individuais', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryChannelControllerObterCanaisIndividuais.queryKey = '/api/v1/channel/canais-individuais'

export const queryChannelControllerObterCanalIndividualPorId = (pathParams, options) => queryFn(options)('/api/v1/channel/canais-individuais/{id}', pathParams)
export const useQueryChannelControllerObterCanalIndividualPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/channel/canais-individuais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryChannelControllerObterCanalIndividualPorId.queryKey = '/api/v1/channel/canais-individuais/{id}'

export const mutationChannelControllerDeletarCanalIndividual = (pathParams, options) => mutationFn('delete', '/api/v1/channel/canais-individuais/{id}', pathParams, {}, options)
export const useMutationChannelControllerDeletarCanalIndividual = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/channel/canais-individuais/{id}', pathParams, {}, options), config)

export const queryChannelControllerObterCanaisGrupos = (queryParams, options) => queryFn(options)('/api/v1/channel/canais-grupo', queryParams)
export const useQueryChannelControllerObterCanaisGrupos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/channel/canais-grupo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryChannelControllerObterCanaisGrupos.queryKey = '/api/v1/channel/canais-grupo'

export const mutationChannelControllerCadastrarCanalGrupo = (options) => mutationFn('post', '/api/v1/channel/canais-grupo', {}, {}, options)
export const useMutationChannelControllerCadastrarCanalGrupo = (config, options) => useMutation(mutationFn('post', '/api/v1/channel/canais-grupo', {}, {}, options), config)

export const queryChannelControllerObterCanaisGruposDisponiveis = (queryParams, options) => queryFn(options)('/api/v1/channel/canais-grupo/disponiveis', queryParams)
export const useQueryChannelControllerObterCanaisGruposDisponiveis = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/channel/canais-grupo/disponiveis', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryChannelControllerObterCanaisGruposDisponiveis.queryKey = '/api/v1/channel/canais-grupo/disponiveis'

export const mutationChannelControllerAtualizarCanalGrupo = (pathParams, options) => mutationFn('put', '/api/v1/channel/canais-grupo/{id}', pathParams, {}, options)
export const useMutationChannelControllerAtualizarCanalGrupo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/channel/canais-grupo/{id}', pathParams, {}, options), config)

export const mutationChannelControllerDeletarCanalGrupo = (pathParams, options) => mutationFn('delete', '/api/v1/channel/canais-grupo/{id}', pathParams, {}, options)
export const useMutationChannelControllerDeletarCanalGrupo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/channel/canais-grupo/{id}', pathParams, {}, options), config)

export const queryChannelControllerObterCanalGrupoPorId = (pathParams, options) => queryFn(options)('/api/v1/channel/canais-grupo/{id}', pathParams)
export const useQueryChannelControllerObterCanalGrupoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/channel/canais-grupo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryChannelControllerObterCanalGrupoPorId.queryKey = '/api/v1/channel/canais-grupo/{id}'

export const queryChannelControllerObterMembroCanalPorId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/channel/canais-grupo/{id}/membros', pathParams, queryParams)
export const useQueryChannelControllerObterMembroCanalPorId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/channel/canais-grupo/{id}/membros', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryChannelControllerObterMembroCanalPorId.queryKey = '/api/v1/channel/canais-grupo/{id}/membros'

export const mutationChannelControllerCadastrarMembroCanal = (pathParams, options) => mutationFn('post', '/api/v1/channel/canais-grupo/{id}/membros', pathParams, {}, options)
export const useMutationChannelControllerCadastrarMembroCanal = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/channel/canais-grupo/{id}/membros', pathParams, {}, options), config)

export const queryChannelControllerObterMembroCanal = (pathParams, options) => queryFn(options)('/api/v1/channel/canais-grupo/membros/{id}', pathParams)
export const useQueryChannelControllerObterMembroCanal = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/channel/canais-grupo/membros/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryChannelControllerObterMembroCanal.queryKey = '/api/v1/channel/canais-grupo/membros/{id}'

export const mutationChannelControllerAtualizarMembro = (pathParams, options) => mutationFn('patch', '/api/v1/channel/canais-grupo/membros/{id}', pathParams, {}, options)
export const useMutationChannelControllerAtualizarMembro = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/channel/canais-grupo/membros/{id}', pathParams, {}, options), config)

export const mutationChannelControllerDeletarMembro = (pathParams, options) => mutationFn('delete', '/api/v1/channel/canais-grupo/membros/{id}', pathParams, {}, options)
export const useMutationChannelControllerDeletarMembro = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/channel/canais-grupo/membros/{id}', pathParams, {}, options), config)

export const mutationChannelControllerSairCanalGrupo = (pathParams, options) => mutationFn('delete', '/api/v1/channel/canais-grupo/{id}/membros/eu', pathParams, {}, options)
export const useMutationChannelControllerSairCanalGrupo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/channel/canais-grupo/{id}/membros/eu', pathParams, {}, options), config)

export const queryChannelControllerListarMembrosPapeis = (queryParams, options) => queryFn(options)('/api/v1/channel/canais-grupo/papeis', queryParams)
export const useQueryChannelControllerListarMembrosPapeis = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/channel/canais-grupo/papeis', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryChannelControllerListarMembrosPapeis.queryKey = '/api/v1/channel/canais-grupo/papeis'

export const queryChannelControllerObterPapelPorId = (pathParams, options) => queryFn(options)('/api/v1/channel/canais-grupo/papeis/{id}', pathParams)
export const useQueryChannelControllerObterPapelPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/channel/canais-grupo/papeis/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryChannelControllerObterPapelPorId.queryKey = '/api/v1/channel/canais-grupo/papeis/{id}'

export const queryChannelControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/channel/canais-grupo/papeis/{chave}', pathParams)
export const useQueryChannelControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/channel/canais-grupo/papeis/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryChannelControllerObterPorChave.queryKey = '/api/v1/channel/canais-grupo/papeis/{chave}'

export const mutationTeleConsultasControllerEnviar = (pathParams, options) => mutationFn('post', '/api/v1/teleconsultas/{id}/upload/{usuarioId}', pathParams, {}, options)
export const useMutationTeleConsultasControllerEnviar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/teleconsultas/{id}/upload/{usuarioId}', pathParams, {}, options), config)

export const queryTeleConsultasControllerDownload = (pathParams, options) => queryFn(options)('/api/v1/teleconsultas/{id}/download/{arquivoId}/notificar/{usuarioId}', pathParams)
export const useQueryTeleConsultasControllerDownload = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/teleconsultas/{id}/download/{arquivoId}/notificar/{usuarioId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.arquivoId && pathParams.usuarioId, ...config }
})
useQueryTeleConsultasControllerDownload.queryKey = '/api/v1/teleconsultas/{id}/download/{arquivoId}/notificar/{usuarioId}'

export const queryTeleConsultasControllerBuscarFuncionario = (pathParams, options) => queryFn(options)('/api/v1/teleconsultas/{id}/funcionario/{funcionarioId}', pathParams)
export const useQueryTeleConsultasControllerBuscarFuncionario = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/teleconsultas/{id}/funcionario/{funcionarioId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.funcionarioId, ...config }
})
useQueryTeleConsultasControllerBuscarFuncionario.queryKey = '/api/v1/teleconsultas/{id}/funcionario/{funcionarioId}'

export const mutationTeleConsultasControllerConectarFuncionario = (pathParams, options) => mutationFn('put', '/api/v1/teleconsultas/{id}/funcionario/{funcionarioId}/conectado', pathParams, {}, options)
export const useMutationTeleConsultasControllerConectarFuncionario = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/teleconsultas/{id}/funcionario/{funcionarioId}/conectado', pathParams, {}, options), config)

export const queryTeleConsultasControllerBuscarCliente = (pathParams, options) => queryFn(options)('/api/v1/teleconsultas/{id}/cliente/{clienteId}', pathParams)
export const useQueryTeleConsultasControllerBuscarCliente = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/teleconsultas/{id}/cliente/{clienteId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.clienteId, ...config }
})
useQueryTeleConsultasControllerBuscarCliente.queryKey = '/api/v1/teleconsultas/{id}/cliente/{clienteId}'

export const mutationTeleConsultasControllerConectarCliente = (pathParams, options) => mutationFn('put', '/api/v1/teleconsultas/{id}/cliente/{clienteId}/conectado', pathParams, {}, options)
export const useMutationTeleConsultasControllerConectarCliente = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/teleconsultas/{id}/cliente/{clienteId}/conectado', pathParams, {}, options), config)

export const mutationTeleConsultasControllerAceitarTermo = (pathParams, options) => mutationFn('put', '/api/v1/teleconsultas/{id}/cliente/{clienteId}/aceite', pathParams, {}, options)
export const useMutationTeleConsultasControllerAceitarTermo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/teleconsultas/{id}/cliente/{clienteId}/aceite', pathParams, {}, options), config)

export const queryTeleConsultasControllerBuscarChats = (pathParams, options) => queryFn(options)('/api/v1/teleconsultas/{id}/chats', pathParams)
export const useQueryTeleConsultasControllerBuscarChats = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/teleconsultas/{id}/chats', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTeleConsultasControllerBuscarChats.queryKey = '/api/v1/teleconsultas/{id}/chats'

export const queryTeleConsultasControllerBuscar = (pathParams, options) => queryFn(options)('/api/v1/teleconsultas/{id}', pathParams)
export const useQueryTeleConsultasControllerBuscar = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/teleconsultas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTeleConsultasControllerBuscar.queryKey = '/api/v1/teleconsultas/{id}'

