import { useState, useRef } from 'react'

import { createContainer } from '@sponte/lib-utils/dist/hooks/useContainer'

import { useDisclosure } from '@sponte/lib-utils/src/hooks/useDisclosure'

function useTeleconsultaHook() {
  const hub = useRef()
  const [hidden, setHidden] = useState(false)
  const [teleconsultaId, setTeleconsultaId] = useState(null)
  const [teleconsultaIsActive, setTeleconsultaIsActive] = useState(null)

  const finalizarTeleconsultaCallback = useRef()
  const modoTeleconsultaCallback = useRef()

  const drawerProps = useDisclosure({
    reducer: (_, { type, payload }, changes) => {
      if (type === useDisclosure.types.close) {
        setHidden(true)

        if (payload.teleconsultaIsActive) {
          return { ...changes, isOpen: true }
        }

        return { ...changes, isOpen: false }
      }

      if (type === useDisclosure.types.open) {
        setHidden(false)

        return { ...changes, isOpen: true }
      }

      return changes
    }
  })

  const iniciarTeleconsulta = ({
    teleconsultaId: teleconsultaIdCallback,
    modoTeleconsultaCallback: modoTeleconsulta,
    finalizarTeleconsultaCallback: finalizaTeleconsulta
  }) => {
    if (!teleconsultaIdCallback) {
      throw new Error('Must be informed teleconsultaId')
    }

    modoTeleconsultaCallback.current = modoTeleconsulta
    setTeleconsultaId(teleconsultaIdCallback)
    drawerProps.onOpen()

    finalizarTeleconsultaCallback.current = finalizaTeleconsulta
  }

  const finalizarTeleconsulta = () => {
    setTeleconsultaId(null)

    if (modoTeleconsultaCallback.current) {
      modoTeleconsultaCallback.current(false)
    }

    setTimeout(() => {
      if (finalizarTeleconsultaCallback.current) {
        finalizarTeleconsultaCallback.current()
        finalizarTeleconsultaCallback.current = null
      }

      drawerProps.onClose()
    }, 500)
  }

  return {
    drawerProps,
    finalizarTeleconsulta,
    hidden,
    hub,
    iniciarTeleconsulta,
    setHidden,
    setTeleconsultaIsActive,
    teleconsultaId,
    teleconsultaIsActive
  }
}

export const [TeleconsultaProvider, useTeleconsulta] = createContainer(useTeleconsultaHook)
