"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SptText = exports.SptTextVariants = void 0;

var _propTypes = _interopRequireDefault(require("@styled-system/prop-types"));

var _propTypes2 = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _styledSystem = require("styled-system");

var _tools = require("@sponte/lib-utils/dist/theme/tools");

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj.default = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n      font-style: ", ";\n    "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n      cursor: pointer;\n    "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n      overflow: hidden;\n      white-space: nowrap;\n      text-overflow: ellipsis;\n    "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n      text-decoration: ", ";\n    "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n      word-break: ", ";\n    "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n      text-transform: ", ";\n    "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", "\n\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    color: ", ";\n    font-size: ", "px;\n    font-weight: ", ";\n    line-height: ", "px;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    color: ", ";\n    font-size: ", "px;\n    font-weight: ", ";\n    line-height: ", "px;\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var SptTextVariants = {
  default: (0, _styledComponents.css)(_templateObject(), (0, _tools.theme)('colors.almostBlack'), (0, _tools.theme)('fontSizes.default'), (0, _tools.theme)('fontWeights.medium'), function (props) {
    return props.theme.fontSizes.medium + props.theme.lineHeightScale;
  }),
  label: (0, _styledComponents.css)(_templateObject2(), (0, _tools.theme)('colors.darkGrey'), (0, _tools.theme)('fontSizes.small'), (0, _tools.theme)('fontWeights.medium'), function (props) {
    return props.theme.fontSizes.small + props.theme.lineHeightScale;
  })
};
exports.SptTextVariants = SptTextVariants;

var SptText = _styledComponents.default.span(_templateObject3(), (0, _tools.switchProp)('variant', SptTextVariants), _tools.fontSize, _styledSystem.space, _styledSystem.color, _styledSystem.fontFamily, _styledSystem.fontWeight, _styledSystem.textAlign, _styledSystem.letterSpacing, _styledSystem.lineHeight, (0, _tools.ifProp)('textTransform', (0, _styledComponents.css)(_templateObject4(), (0, _tools.prop)('textTransform'))), (0, _tools.ifProp)('wordBreak', (0, _styledComponents.css)(_templateObject5(), (0, _tools.prop)('wordBreak'))), (0, _tools.ifProp)('textDecoration', (0, _styledComponents.css)(_templateObject6(), (0, _tools.prop)('textDecoration'))), (0, _tools.ifProp)('truncate', (0, _styledComponents.css)(_templateObject7())), (0, _tools.ifProp)('onClick', (0, _styledComponents.css)(_templateObject8())), (0, _tools.ifProp)('fontStyle', (0, _styledComponents.css)(_templateObject9(), (0, _tools.prop)('fontStyle'))));

exports.SptText = SptText;
SptText.displayName = 'SptText';
SptText.propTypes = _objectSpread({
  truncate: _propTypes2.default.bool,
  variant: _propTypes2.default.oneOf(Object.keys(SptTextVariants))
}, _propTypes.default.space, {}, _propTypes.default.color, {}, _propTypes.default.typography);
SptText.defaultProps = _objectSpread({
  variant: 'default',
  fontFamily: 'default'
}, !(0, _tools.prop)('variant') && {
  fontSize: 'default'
});