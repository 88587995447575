"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSnackbar = useSnackbar;

var _react = require("react");

var _reactSnackbarAlert = require("react-snackbar-alert");

function useSnackbar() {
  var context = (0, _react.useContext)(_reactSnackbarAlert.SnackbarContext);

  if (context === undefined) {
    throw new Error('Must be used within a SnackbarProvider');
  }

  var createSnackbar = function createSnackbar(theme, message) {
    var config = typeof theme === 'string' ? {
      theme: theme,
      message: message
    } : theme;
    return context.createSnackbar(config);
  };

  return {
    createSnackbar: createSnackbar
  };
}