"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SptLoading = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledSystem = require("styled-system");

var _tools = require("@sponte/lib-utils/dist/theme/tools");

var _Flex = require("../../elements/Flex");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n  width: ", "px;\n  transform-origin: center;\n  animation: rotate 2s linear infinite;\n\n  circle {\n    fill: none;\n    stroke: ", ";\n    stroke-width: 4;\n    stroke-dasharray: 1, 200;\n    stroke-dashoffset: 0;\n    animation: dash 1.5s ease-in-out infinite;\n  }\n\n  @keyframes rotate {\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n\n  @keyframes dash {\n    0% {\n      stroke-dasharray: 1, 200;\n      stroke-dashoffset: 0;\n    }\n    50% {\n      stroke-dasharray: 90, 200;\n      stroke-dashoffset: -35px;\n    }\n    100% {\n      stroke-dashoffset: -125px;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var SptLoadingSizes = {
  sm: 18,
  md: 24,
  lg: 44,
  xl: 64
};

var SptLoadingSpinner = _styledComponents.default.svg.attrs({
  viewBox: '25 25 50 50',
  children: _react.default.createElement("circle", {
    cx: "50",
    cy: "50",
    r: "20"
  })
})(_templateObject(), _styledSystem.space, (0, _tools.ifProp)('width', (0, _tools.prop)('width'), (0, _tools.switchProp)('size', SptLoadingSizes)), function (props) {
  return (0, _tools.theme)("colors.".concat(props.color));
});

SptLoadingSpinner.displayName = 'SptLoadingSpinner';
var SptLoadingVariants = {
  spinner: SptLoadingSpinner
};

var SptLoadingFull = function SptLoadingFull(_ref) {
  var children = _ref.children,
      size = _ref.size,
      props = _objectWithoutProperties(_ref, ["children", "size"]);

  return _react.default.createElement(_Flex.SptFlex, props, children);
};

var SptLoadingNoWrapper = function SptLoadingNoWrapper(_ref2) {
  var children = _ref2.children;
  return children;
};

SptLoadingFull.displayName = 'SptLoadingFull';
SptLoadingFull.defaultProps = {
  flex: 1,
  minWidth: '100%',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center'
};
SptLoadingNoWrapper.displayName = 'SptLoadingNoWrapper';

var SptLoading = function SptLoading(props) {
  var full = props.full,
      variant = props.variant;
  var Wrapper = full ? SptLoadingFull : SptLoadingNoWrapper;
  var Component = SptLoadingVariants[variant];
  return _react.default.createElement(Wrapper, props, _react.default.createElement(Component, props));
};

exports.SptLoading = SptLoading;
SptLoading.displayName = 'SptLoading';
SptLoading.propTypes = {
  full: _propTypes.default.bool,
  size: _propTypes.default.oneOf(Object.keys(SptLoadingSizes)),
  variant: _propTypes.default.oneOf(Object.keys(SptLoadingVariants))
};
SptLoading.defaultProps = {
  full: false,
  size: 'md',
  color: 'primary',
  variant: 'spinner'
};