"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTheme = useTheme;

var _react = require("react");

var _styledComponents = require("styled-components");

function useTheme() {
  var context = (0, _react.useContext)(_styledComponents.ThemeContext);

  if (context === undefined) {
    throw new Error('[useTheme]: must be used within a SptThemeProvider');
  }

  return context;
}