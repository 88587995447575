"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ValueBoletoformatted = exports.dateDue = exports.checkerCode = exports.currency = exports.bank = exports.boletoAmount = exports.preetyNumber = exports.boletoValidator = exports.number = exports.calculusChecker = exports.barcode = void 0;

var barcode = function barcode(boletoNumber) {
  return boletoNumber.replace(/^(\d{4})(\d{5})\d{1}(\d{10})\d{1}(\d{10})\d{1}(\d{15})$/, '$1$5$2$3$4');
};

exports.barcode = barcode;

var calculusChecker = function calculusChecker(barcodeDigits) {
  var digits = barcodeDigits;

  if (typeof digits === 'string') {
    digits = digits.split('');
  }

  digits.reverse();
  var sum = 0;

  for (var i = 0; i < digits.length; i += 1) {
    sum += (i % 8 + 2) * digits[i];
  }

  return (11 - sum % 11) % 10 || 1;
};

exports.calculusChecker = calculusChecker;

var number = function number(boletoNumber) {
  return boletoNumber.replace(/[^0-9]+/gi, '');
};

exports.number = number;

var boletoValidator = function boletoValidator(boletoNumber) {
  var boletoFormatted = number(boletoNumber);
  if (boletoFormatted.length !== 47) return false;
  var barcodeDigits = barcode(boletoFormatted).split('');
  var checkerCode = barcodeDigits.splice(4, 1);
  return calculusChecker(barcodeDigits).toString() === checkerCode.toString();
};

exports.boletoValidator = boletoValidator;

var preetyNumber = function preetyNumber(boletoNumber) {
  return boletoNumber.replace(/^(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})$/, '$1.$2 $3.$4 $5.$6 $7 $8');
};

exports.preetyNumber = preetyNumber;

var boletoAmount = function boletoAmount(boletoNumber) {
  var boletoFormatted = number(boletoNumber);
  return (barcode(boletoFormatted).substr(9, 10) / 100.0).toFixed(2);
};

exports.boletoAmount = boletoAmount;

var bank = function bank(boletoNumber) {
  var boletoFormatado = number(boletoNumber);
  var codBank = barcode(boletoFormatado).substr(0, 3);
  if (codBank === '001') return 'Banco do Brasil';
  if (codBank === '007') return 'BNDES';
  if (codBank === '033') return 'Santander';
  if (codBank === '069') return 'Crefisa';
  if (codBank === '077') return 'Banco Inter';
  if (codBank === '102') return 'XP Investimentos';
  if (codBank === '104') return 'Caixa Econômica Federal';
  if (codBank === '140') return 'Easynvest';
  if (codBank === '197') return 'Stone';
  if (codBank === '208') return 'BTG Pactual';
  if (codBank === '212') return 'Banco Original';
  if (codBank === '237') return 'Bradesco';
  if (codBank === '260') return 'Nu Pagamentos';
  if (codBank === '314') return 'Itaú';
  if (codBank === '389') return 'Banco Mercantil do Brasil';
  if (codBank === '422') return 'Banco Safra';
  if (codBank === '505') return 'Credit Suisse';
  if (codBank === '633') return 'Banco Rendimento';
  if (codBank === '652') return 'Itaú Unibanco';
  if (codBank === '735') return 'Banco Neon';
  if (codBank === '739') return 'Banco Cetelem';
  if (codBank === '745') return 'Citibank';
  return 'Unknown';
};

exports.bank = bank;

var currency = function currency(boletoNumber) {
  switch (barcode(boletoNumber)[3]) {
    case '9':
      return {
        code: 'BRL',
        symbol: 'R$',
        decimal: ','
      };

    default:
      return 'Unknown';
  }
};

exports.currency = currency;

var checkerCode = function checkerCode(boletoNumber) {
  return barcode(boletoNumber)[4];
};

exports.checkerCode = checkerCode;

var dateDue = function dateDue(boletoNumber) {
  var refDate = new Date(876236400000);
  var days = barcode(boletoNumber).substr(5, 4);
  return new Date(refDate.getTime() + days * 86400000);
};

exports.dateDue = dateDue;

var ValueBoletoformatted = function ValueBoletoformatted(boletoNumber) {
  var currencyBoleto = currency(boletoNumber);
  if (currencyBoleto === 'Unknown') return boletoAmount(boletoNumber);
  return "".concat(currencyBoleto.symbol, " ").concat(boletoAmount(boletoNumber).replace('.', currencyBoleto.decimal));
};

exports.ValueBoletoformatted = ValueBoletoformatted;