"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fontSize = exports.palette = exports.theme = exports.ifNotProp = exports.ifProp = exports.switchProp = exports.prop = void 0;

function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

var prop = function prop(path, defaultValue) {
  return function () {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    if (typeof props[path] !== 'undefined') {
      return props[path];
    }

    if (path && path.indexOf('.') > 0) {
      var paths = path.split('.');
      var length = paths.length;
      var object = props[paths[0]];
      var index = 1;

      while (object != null && index < length) {
        object = object[paths[index]];
        index += 1;
      }

      if (typeof object !== 'undefined') {
        return object;
      }
    }

    return defaultValue;
  };
};

exports.prop = prop;

var switchProp = function switchProp(needle, cases, defaultCase) {
  return function () {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var value = typeof needle === 'function' ? needle(props) : prop(needle)(props);
    var finalCases = typeof cases === 'function' ? cases(props) : cases;

    if (value in finalCases) {
      return finalCases[value];
    }

    return defaultCase;
  };
};

exports.switchProp = switchProp;

var parseFunction = function parseFunction(props, test) {
  return Boolean(test(props));
};

var parseObject = function parseObject(props, test) {
  var keys = Object.keys(test);
  var length = keys.length;

  for (var index = 0; index < length; index += 1) {
    var key = keys[index];
    var expected = test[key];
    var value = prop(key)(props);

    if (expected !== value) {
      return false;
    }
  }

  return true;
};

var parseString = function parseString(props, test) {
  return Boolean(prop(test)(props));
};

var parseMap = {
  function: parseFunction,
  object: parseObject,
  string: parseString
};

var ifProp = function ifProp(test) {
  var pass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var fail = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  return function () {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var result = true;

    if (Array.isArray(test)) {
      var length = test.length;
      var index = 0;

      while (result && index < length) {
        result = parseMap[_typeof(test[index])](props, test[index]);
        index += 1;
      }
    } else {
      result = parseMap[_typeof(test)](props, test);
    }

    var value = result ? pass : fail;
    return typeof value === 'function' ? value(props) : value;
  };
};

exports.ifProp = ifProp;

var ifNotProp = function ifNotProp(test, pass, fail) {
  return ifProp(test, fail, pass);
};

exports.ifNotProp = ifNotProp;

var theme = function theme(path, defaultValue) {
  return function (props) {
    return prop(path, defaultValue)(props.theme);
  };
};

exports.theme = theme;
var paletteTones = ['main', 'light', 'dark'];
var paletteColors = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];

var isPaletteTone = function isPaletteTone(key) {
  return paletteTones.includes(key);
};

var isPaletteColor = function isPaletteColor(key) {
  return paletteColors.includes(key);
};

var palette = function palette(colorOrTone, toneOrDefaultValue, defaultValue) {
  return function (props) {
    var isTone = isPaletteTone(colorOrTone);
    var isColor = isPaletteColor(colorOrTone);
    var color = isColor ? colorOrTone : props.palette;
    var tone;

    if (isTone) {
      tone = colorOrTone;
    } else if (isPaletteTone(toneOrDefaultValue)) {
      tone = toneOrDefaultValue;
    } else {
      tone = props.tone || 'main';
    }

    var finalDefaultValue = toneOrDefaultValue !== tone ? toneOrDefaultValue : defaultValue;

    if (!props.theme.palette || !props.theme.palette[color] || !props.theme.palette[color][tone]) {
      return finalDefaultValue;
    }

    return props.theme.palette[color][tone];
  };
};

exports.palette = palette;

var fontSize = function fontSize(value) {
  var size = typeof value === 'number' ? value.toString() : value;
  return function (props) {
    var val = props.fontSize || size || 'default';
    var fs = theme("fontSizes.".concat(val))(props);
    var lineHeightScale = theme('lineHeightScale')(props);

    if (!fs) {
      return {};
    }

    return {
      fontSize: "".concat(fs, "px"),
      lineHeight: "".concat(fs + lineHeightScale, "px")
    };
  };
};

exports.fontSize = fontSize;